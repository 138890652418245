import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn,
  Router
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Dictionary, ViewModes } from 'src/app/shared/models/global';
import { of, tap } from 'rxjs';
import { ResidentService } from 'src/app/shared/services/resident/resident.service';
import { Resident } from '@resparke/models';

export const residentResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const residentsService = inject(ResidentService);
    const appState = inject(AppStateService);
    const router = inject(Router);
    const residentId = window.sessionStorage.getItem(ViewModes.RESIDENT_VIEW) ||
      window.sessionStorage.getItem(ViewModes.RESIDENT_LOCK) ||
      route.params.residentId;
    if (residentId) {
      appState.set<string>('currentResidentId', residentId);
      // if no current resident in appstate, fetch it from collection
      if (!appState.get<Resident>('currentResident')) {
        const facilityId = appState.get<string>('currentFacilityId');
        const residentCollection = appState.get<Dictionary<Resident[]>>('residentsCollection') || {};
        const resident = (residentCollection[facilityId] || []).find(val => val.id === residentId);
        if (resident) {
          appState.set<Resident>('currentResident', resident);
          return of(resident);
        }
        // resident was not present in collection, fetch it from API
        return residentsService.getResident({
          residentId,
          facilityId,
        }).pipe(
          tap(resident => {
            // if resident is null because of incorrect residentId, redirect away
            if (!resident) {
              router.navigate(['/'])
            }
          })
        )
      }
    }
    return undefined;
  };
